import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import About from './About';
import ExperiencePro from './ExperiencePro';
import Projet from './Projet';
import Skills from './Skills';
import './css_components/MainContent.css';


function MainContent({ activeComponent }) {
  const components = {
    about: <About />,
    experience: <ExperiencePro />,
    projet: <Projet />,
    skills: <Skills />,
  };

  return (
    <div className="main-content">
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={activeComponent}
          timeout={300}
          classNames="fade"
        >
          <div className="component-wrapper">
            {components[activeComponent]}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default MainContent;
