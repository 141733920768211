import React, { createContext, useState, useContext } from 'react';

// Créer un contexte de langue
const LanguageContext = createContext();

// Fournisseur de contexte qui gère l'état de la langue
export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('fr'); // Par défaut, 'fr'

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte de langue
export const useLanguage = () => {
  return useContext(LanguageContext);
};
