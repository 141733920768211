// ExperiencePro.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { MdArrowForwardIos, MdKeyboardArrowRight, MdLocationPin } from "react-icons/md";
import { CgWorkAlt } from "react-icons/cg";
import './css_components/experiences.css'; // Importer vos propres styles
import { fetchData, API_URL } from './strapi'; // Importer API_URL et fetchData
import { IoMdClose } from "react-icons/io";
import ReactMarkdown from 'react-markdown';

import { BiSolidSchool } from "react-icons/bi";
import { FaUserGraduate } from "react-icons/fa";
import { CSSTransition, SwitchTransition } from 'react-transition-group'; // Importer les composants nécessaires

import fr from '../locales/fr.json'; // Chemin mis à jour
import en from '../locales/en.json'; // Chemin mis à jour
import { useLanguage } from './LanguageContext'; // Hook pour accéder à la langue


// Définir l'élément racine pour la modal
Modal.setAppElement('#root');

function ExperiencePro() {
  const [experiences, setExperiences] = useState([]);
  const [isExperienceVisible, setIsExperienceVisible] = useState(true); // État pour basculer entre expériences et formations
  const [isModalActive, setIsModalActive] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null); // Pour stocker l'expérience courante
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formations, setFormations] = useState([]);
  const { selectedLanguage } = useLanguage(); // Utiliser le contexte de langue
  const texts = selectedLanguage === 'fr' ? fr : en; // Utiliser les traductions selon la langue sélectionnée
  

 // Récupérer les données depuis Strapi pour experience
 useEffect(() => {
  const fetchProfileData = async () => {
    try {
      // Passer le paramètre de tri ici
      const response = await fetchData('experience-cards', selectedLanguage, { sort: ['order:asc'] });
      setExperiences(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching profile data:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  fetchProfileData();
}, [selectedLanguage]); // Recharger les données si la langue change

// Récupérer les données depuis Strapi pour formation
useEffect(() => {
  const fetchProfileData = async () => {
    try {
      // Passer le paramètre de tri ici
      const response = await fetchData('formation-cards', selectedLanguage, { sort: ['order:asc'] });
      setFormations(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching profile data:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  fetchProfileData();
}, [selectedLanguage]); // Recharger les données si la langue change

  // Fonction pour ouvrir la modal
  const openModal = (experience) => {
    setCurrentExperience(experience);
    setIsModalActive(true);
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setIsModalActive(false);
    setCurrentExperience(null);
  };

  // Fonction pour basculer entre les containers
  const handleSwitch = () => {
    setIsExperienceVisible(!isExperienceVisible); // Bascule entre expériences et formations
  };

  if (error) return <p>Erreur : {error}</p>;

  return (
    <div className='experience-page'>
      {/* Bouton de bascule */}
      <div className='button-experience-container'>
        <button className='button-switch' onClick={handleSwitch}>
          <p><MdKeyboardArrowRight /></p>
          <span>{isExperienceVisible ? texts.toggle_button.show_education : texts.toggle_button.show_experiences}</span>
        
        </button>
      </div>
      {/* Transition */}
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isExperienceVisible ? 'experience' : 'formation'}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          {isExperienceVisible ? (
            <div className="experience-container">
              {experiences.map((experience) => (
                <div className="card-experience" key={experience.id}>
                  <div className="titre-experience">
                    <h3>{experience.attributes.company}</h3>
                    <p>{experience.attributes.date}</p>
                  </div>

                  <div className="photo-experience">
                    <div className="photo-container">
                      {experience.attributes.logo?.data?.length > 0 ? (
                        <img
                          src={`${API_URL}${experience.attributes.logo.data[0].attributes.url}`}
                          alt={experience.attributes.company}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>

                  <div className="icone-experience">
                    <div className="test">
                      <span><MdLocationPin /></span>
                      <p>{experience.attributes.location}</p>
                    </div>
                    <div className="test">
                      <span><CgWorkAlt /></span>
                      <p>{experience.attributes.contract}</p>
                    </div>
                  </div>

                  <div className="filtre-experience-container">
                    <div className="post-experience">
                      <p>{experience.attributes.title}</p>
                    </div>
                    <div className="filtre-experiences">
                      {experience.attributes.keywords && experience.attributes.keywords.map((keyword, index) => (
                        <span key={index} className="keyword-label">{keyword}</span>
                      ))}
                    </div>
                  </div>

                  <div className="separate-experience"></div>

                  {/* Div pour ouvrir la modal */}
                  <div
                    className="more-experience"
                    onClick={() => openModal(experience)}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                  >
                    <p>En savoir plus</p>
                    <span><MdArrowForwardIos /></span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            /* Affichage du container des formations */
            <div className="formation-container">
              {formations.map((formation) => (
                <div className="card-formation" key={formation.id}>
                  <div className="titre-experience">
                    <h3>{formation.attributes.title}</h3>
                    <p>{formation.attributes.date}</p>
                  </div>

                  <div className="photo-experience">
                    <div className="photo-container">
                      {formation.attributes.logo?.data?.length > 0 ? (
                        <img
                          src={`${API_URL}${formation.attributes.logo.data[0].attributes.url}`}
                          alt={formation.attributes.locate}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>

                  <div className="icone-formation">
                    <div className="test">
                      <span><FaUserGraduate /></span>
                      <p>{formation.attributes.grade}</p>
                    </div>
                    <div className="test">
                      <span><BiSolidSchool /> </span>
                      <p>{formation.attributes.school}</p>
                    </div>
                    <div className="test">
                      <span><MdLocationPin /></span>
                      <p>{formation.attributes.locate}</p>
                    </div>
                  </div>

                  <div className="filtre-experience-container">
                    
                    <div className="filtre-experiences">
                      {formation.attributes.keyword && formation.attributes.keyword.map((keyword, index) => (
                        <span key={index} className="keyword-label">{keyword}</span>
                      ))}
                    </div>
                  </div>

                  

                  
                  
                </div>
              ))}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>

      

      {/* Modal avec React Modal */}
      {currentExperience && (
        <Modal
          isOpen={isModalActive}
          onRequestClose={closeModal}
          contentLabel="Modal expérience"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div className="modal-header">
            <h2>{currentExperience.attributes.modaltitle}</h2>
            <button onClick={closeModal} className="close-button"><IoMdClose /></button>
          </div>
          <div className="modal-body">
            <ReactMarkdown>{currentExperience.attributes.description}</ReactMarkdown>
          </div>
          <div className="modal-footer">
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ExperiencePro;
