import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { MdArrowForwardIos, MdKeyboardArrowRight, MdLocationPin } from "react-icons/md";
import { CgWorkAlt } from "react-icons/cg";
import './css_components/projet.css'; // Importer vos propres styles
import { fetchData, API_URL } from './strapi'; // Importer API_URL et fetchData
import { IoMdClose } from "react-icons/io";
import { FaUserGraduate } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";
import ReactMarkdown from 'react-markdown';
import fr from '../locales/fr.json'; // Chemin mis à jour
import en from '../locales/en.json'; // Chemin mis à jour
import { useLanguage } from './LanguageContext'; // Hook pour accéder à la langue

// Définir l'élément racine pour la modal
Modal.setAppElement('#root');

function Projet() {
  const [projet, setProjet] = useState([]);
  
  const [isModalActive, setIsModalActive] = useState(false);
  const [currentProjet, setCurrentProjet] = useState(null); // Pour stocker l'expérience courante
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedLanguage } = useLanguage(); // Utiliser le contexte de langue
  const texts = selectedLanguage === 'fr' ? fr : en; // Utiliser les traductions selon la langue sélectionnée

  // Récupérer les données depuis Strapi pour formation
useEffect(() => {
  const fetchProfileData = async () => {
    try {
      // Passer le paramètre de tri ici
      const response = await fetchData('projet-cards', selectedLanguage, { sort: ['order:asc'] });
      setProjet(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching profile data:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  fetchProfileData();
}, [selectedLanguage]); // Recharger les données si la langue change

  // Fonction pour ouvrir la modal
  const openModal = (projet) => {
    setCurrentProjet(projet);
    setIsModalActive(true);
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setIsModalActive(false);
    setCurrentProjet(null);
  };

  



  return (
    <div className='projet-container'>
    
      {projet.length > 0 ? (
        projet.map((projetItem) => (
          <div key={projetItem.id} className="projet-card">
            <div className="img-cover">
              {projetItem.attributes.imgProjet?.data?.length > 0 ? (
                      <img
                        src={`${API_URL}${projetItem.attributes.imgProjet.data[0].attributes.url}`}
                        alt={projetItem.attributes.projetTitle}
                        style={{ width: '100%', height: '100%', objectFit: 'cover'}}
                      />
                    ) : (
                      <p></p>
                    )}
            </div>
            <div className="projet-content">
              <h1>{projetItem.attributes.projetTitle}</h1>
              <div className="projet-keyword">
                {projetItem.attributes.keywordProjet && projetItem.attributes.keywordProjet.map((keyword, index) => (
                      <span key={index} className="one-keyword-projet">{keyword}</span>
                    ))}
              </div>
            </div>
            
            <div className="separate-experience"></div>
            <div
              className="more-projet"
              onClick={() => openModal(projetItem)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <p>En savoir plus</p>
              <p id="arrow-project"><MdArrowForwardIos /></p>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}

      {/* Modal avec React Modal */}
      {currentProjet && (
        <Modal
          isOpen={isModalActive}
          onRequestClose={closeModal}
          contentLabel="Modal expérience"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div className="modal-header">
            <h2>{currentProjet.attributes.modaltitleprojet}</h2>
            <button onClick={closeModal} className="close-button"><IoMdClose /></button>
          </div>
          <div className="modal-body">
            <ReactMarkdown>{currentProjet.attributes.descriptionmodal}</ReactMarkdown>
          </div>
          <div className="modal-footer">
            
          </div>
        </Modal>
      )}

    </div>
  );
}

export default Projet;
