// src/api/strapi.js
import qs from 'qs';

// Log les valeurs pour vérifier leur présence
console.log('API_URL:', process.env.REACT_APP_API_URL);
console.log('API_TOKEN:', process.env.REACT_APP_API_TOKEN);

// Variables d'environnement
const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

// Vérifier si API_URL est défini
if (!API_URL) {
    throw new Error('API_URL is not defined. Check your environment variables.');
}

// Vérifier si API_TOKEN est défini
if (!API_TOKEN) {
    throw new Error('API_TOKEN is not defined. Check your environment variables.');
}

async function fetchData(endpoint, locale = 'fr', params = {}) {
    const headers = new Headers({
        'Authorization': `Bearer ${API_TOKEN}`,
        'Content-Type': 'application/json'
    });

    // Combiner les paramètres de base avec les paramètres supplémentaires
    const query = {
        populate: '*',
        locale: locale,
        ...params
    };

    // Construire la chaîne de requête
    const queryString = qs.stringify(query, {
        encodeValuesOnly: true, // Encodage uniquement des valeurs, pas des clés
    });

    // Construire l'URL complète
    const url = `${API_URL}/api/${endpoint}?${queryString}`;
    
    console.log(`Fetching data from: ${url}`);

    try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
            console.error('Network response was not ok:', response.status);
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Failed to fetch data from ${endpoint}:`, error);
        throw error;
    }
}

// Exporter les fonctions et variables pour les utiliser ailleurs
export { fetchData, API_URL };
