import './css_components/navigation.css';
import React, { useState, useEffect } from 'react'; // Import de useState et useEffect
import { slide as Menu } from 'react-burger-menu';
import Flag from 'react-world-flags';
import fr from '../locales/fr.json';
import en from '../locales/en.json';
import { useLanguage } from './LanguageContext'; // Import correct du hook
import { fetchData, API_URL } from './strapi'; // Import de fetchData et API_URL

function Navigation({ activeComponent, setActiveComponent }) {
  // Utilisation du contexte pour gérer la langue
  const { selectedLanguage, setSelectedLanguage } = useLanguage();

  // État pour stocker l'URL du CV
  const [cvUrl, setCvUrl] = useState('');

  // Fonction pour gérer le clic sur les éléments du menu
  const handleMenuClick = (component) => {
    setActiveComponent(component);
  };

  // useEffect pour récupérer l'URL du CV lorsque la langue change
  useEffect(() => {
    const loadCVData = async () => {
      try {
        const response = await fetchData('cv', selectedLanguage);
        // Extraire l'URL du CV depuis la réponse
        const cvData = response.data;
        if (cvData && cvData.attributes && cvData.attributes.cv && cvData.attributes.cv.data && cvData.attributes.cv.data.attributes) {
          const cvFileUrl = cvData.attributes.cv.data.attributes.url;
          // Si l'URL est relative, ajouter API_URL devant
          const fullCvUrl = cvFileUrl.startsWith('http') ? cvFileUrl : `${API_URL}${cvFileUrl}`;
          setCvUrl(fullCvUrl);
        } else {
          console.error("Structure de réponse inattendue lors de la récupération du CV");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du CV :", error);
      }
    };

    loadCVData();
  }, [selectedLanguage]); // Le useEffect se déclenche lorsque la langue change

  // Fonction de téléchargement en fonction de la langue
  const handleDownloadCV = () => {
    if (cvUrl) {
      window.open(cvUrl, '_blank'); // Ouvre le lien dans un nouvel onglet
    } else {
      console.error("L'URL du CV n'est pas disponible.");
    }
  };

  const handleLanguageSwitch = (lang) => {
    setSelectedLanguage(lang); // Mettre à jour la langue dans le contexte
  };

  // Choisir les textes selon la langue sélectionnée
  const texts = selectedLanguage === 'fr' ? fr : en;

  return (
    <>
      {/* Navigation pour desktop */}
      <div className="desktop-only">
        <div className="navigation-container">
          <nav className="navbar">
            <div
              className={`button-container about ${activeComponent === 'about' ? 'active' : ''}`}
              onClick={() => setActiveComponent('about')}
            >
              {/* Remplacer le texte "À PROPOS" par le texte venant du JSON */}
              <h1 className="button-title">{texts.navigation.about}</h1>
              <p className="button-description">
                {texts.navigation.about_description}
              </p>
            </div>
            <div
              className={`button-container experience ${activeComponent === 'experience' ? 'active' : ''}`}
              onClick={() => setActiveComponent('experience')}
            >
              <h1 className="button-title">{texts.navigation.experiences}</h1>
              <p className="button-description">
              {texts.navigation.experiences_description}
              </p>
            </div>
            <div
              className={`button-container projet ${activeComponent === 'projet' ? 'active' : ''}`}
              onClick={() => setActiveComponent('projet')}
            >
              <h1 className="button-title">{texts.navigation.projects}</h1>
              <p className="button-description">
              {texts.navigation.projects_description}
              </p>
            </div>
            <div
              className={`button-container skills ${activeComponent === 'skills' ? 'active' : ''}`}
              onClick={() => setActiveComponent('skills')}
            >
              <h1 className="button-title">{texts.navigation.skills}</h1>
              <p className="button-description">
              {texts.navigation.skills_description}
              </p>
            </div>
          </nav>
          <div className="info-container-nav">
            <button 
              className="info-button" 
              onClick={handleDownloadCV}
            >
              {texts.download_cv}
            </button>
            <div className="info-langage">
              <div
                className={`lang-option ${selectedLanguage === 'fr' ? 'active' : ''}`}
                onClick={() => handleLanguageSwitch('fr')}
              >
                <Flag code="FR" height="16" />
                <p>Français</p>
                {selectedLanguage === 'fr' && <div className="underline"></div>}
              </div>
              <div
                className={`lang-option ${selectedLanguage === 'en' ? 'active' : ''}`}
                onClick={() => handleLanguageSwitch('en')}
              >
                <Flag code="GB" height="16" />
                <p>English</p>
                {selectedLanguage === 'en' && <div className="underline"></div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Menu burger pour mobile */}
      <div className="mobile-only">
        <Menu right>
          <a onClick={() => handleMenuClick('about')} className="menu-item" href="#">
            {texts.navigation.about}
          </a>
          <a onClick={() => handleMenuClick('experience')} className="menu-item" href="#">
            EXPÉRIENCES
          </a>
          <a onClick={() => handleMenuClick('projet')} className="menu-item" href="#">
            PROJETS
          </a>
          <a onClick={() => handleMenuClick('skills')} className="menu-item" href="#">
            SKILLS
          </a>
          <a className="menu-item" href="#" onClick={handleDownloadCV}>
            DOWNLOAD CV
          </a>
          <div className="menu-lang-options">
            <div
              className={`lang-option ${selectedLanguage === 'fr' ? 'active' : ''}`}
              onClick={() => handleLanguageSwitch('fr')}
            >
              <Flag code="FR" height="24" />
            </div>
            <div
              className={`lang-option ${selectedLanguage === 'en' ? 'active' : ''}`}
              onClick={() => handleLanguageSwitch('en')}
            >
              <Flag code="GB" height="24" />
            </div>
          </div>
        </Menu>
      </div>
    </>
  );
}

export default Navigation;
