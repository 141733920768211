import React, { useState, useEffect } from 'react';
import './css_components/skill.css'; // Importer vos propres styles
import { IoLogoPython } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { TbSql } from "react-icons/tb";
import { SiPhp } from "react-icons/si";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import { IoTerminalSharp } from "react-icons/io5";
import { FaLinux } from "react-icons/fa";
import { SiPostman } from "react-icons/si";
import { FaWebflow } from "react-icons/fa6";
import { FaMobile } from "react-icons/fa6";
import { FaWordpress } from "react-icons/fa";
import { SiStrapi } from "react-icons/si";
import { IoIosLink } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import { useLanguage } from './LanguageContext'; // Hook pour accéder à la langue
import fr from '../locales/fr.json';
import en from '../locales/en.json';


function Skills() {
  
  const { selectedLanguage } = useLanguage(); // Utiliser le contexte de langue
  const texts = selectedLanguage === 'fr' ? fr : en; // Utiliser les traductions selon la langue sélectionnée

  return (
    
    <div className="container-skills-page">
      <h3>{texts.hard_skills}</h3>
      <div className='bloc-skills'>
        <h4>{texts.programming_languages}</h4>
          <div className='icone-skills'>
            <IoLogoPython/>
            <FaReact />
            <IoLogoJavascript />
            <TbSql />
            <SiPhp />
            <FaHtml5 />
            <FaCss3Alt />
          </div>
          <h4>{texts.deployment_backend}</h4>
            <div className='icone-skills'>
              <FaDocker />
              <IoTerminalSharp />
              <FaLinux />
              <SiPostman />
            </div>
          <h4>{texts.cms_no_code_low_code}</h4>
            <div className='icone-skills'>
              
              <FaWebflow />
              <FaMobile />
              <FaWordpress />
              <SiStrapi />
            </div>
          <h4>{texts.google_cloud_platform}</h4>
            <div className='GCP-skills'>  
              <div className='icone-skills'>
              <IoMdArrowDropright />
              </div>
              <p>{texts.cloud_storage}</p>
            </div>
            <div className='GCP-skills'>  
              <div className='icone-skills'>
              <IoMdArrowDropright />
              </div>
              <p>{texts.compute_engine}</p>
            </div>
            <div className='GCP-skills'>  
              <div className='icone-skills'>
              <IoMdArrowDropright />
              </div>
              <p> {texts.cloud_scheduler}</p>
            </div>
            <div className='GCP-skills'>  
              < div className='icone-skills'>
              <IoMdArrowDropright />
              </div>
              <p> {texts.cloud_function}</p>
            </div>
            <div className='GCP-skills'>
              <div className='icone-skills'>
              <IoMdArrowDropright />
              </div>
                <p> {texts.service_account}</p>
            </div>
            <div className='GCP-skills'>
              <div className='icone-skills'>
              <IoMdArrowDropright />
                </div>
                <p> {texts.api}</p>
            </div>
      </div>
      <h3>{texts.soft_skills}</h3>
      <div className='bloc-skills'>
        <h4>{texts.it_project_management}</h4>
          <div className='icone-softskills'>
              <p> {texts.backlog}</p>
              <p>{texts.testing}</p>
              <p>{texts.agile_methodology}</p>
              <p>{texts.lead_management}</p>
              <p>{texts.support}</p>
          </div>
      </div>
      
        
    </div>
  );
}



export default Skills;
