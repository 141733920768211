import React, { useState, useEffect } from 'react';
import './App.css';
import MainContent from './components/MainContent';
import Navigation from './components/Navigation';
import Loader from './components/Loader';
import { LanguageProvider } from './components/LanguageContext';
import { fetchData } from './components/strapi'; // Importer fetchData de Strapi

function App() {
  const [activeComponent, setActiveComponent] = useState('about');
  const [loading, setLoading] = useState(true); // État de chargement
  const [profileData, setProfileData] = useState(null); // Les données récupérées de Strapi

  // Fonction pour précharger des images
  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  // Récupération des données API + Préchargement des images
  useEffect(() => {
    const loadDataAndImages = async () => {
      try {
        // Charger les données de Strapi (par exemple, pour l'about)
        const profileResponse = await fetchData('about', 'fr'); // Récupérer les données en français
        const profileData = profileResponse.data; // Stocker les données récupérées
        setProfileData(profileData);

        // Si des images doivent être préchargées, lancer leur préchargement ici
        if (profileData?.attributes?.profile?.data?.[0]?.attributes?.url) {
          const imageUrl = `${process.env.REACT_APP_API_URL}${profileData.attributes.profile.data[0].attributes.url}`;
          await preloadImage(imageUrl); // Précharger l'image
        }

        // Tout est chargé, on peut arrêter le loader
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des données ou des images :', error);
        setLoading(false); // Désactiver le loader même en cas d'erreur pour éviter un blocage
      }
    };

    loadDataAndImages(); // Appel à la fonction asynchrone
  }, []);

  // Si en cours de chargement, afficher le Loader
  if (loading) {
    return <Loader />;
  }

  // Rendu principal de l'application une fois le chargement terminé
  return (
    <LanguageProvider>
      <div className="base">
        <Navigation activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
        <div className="contenu">
          <MainContent activeComponent={activeComponent} profileData={profileData} /> {/* Passer les données récupérées */}
        </div>
      </div>
    </LanguageProvider>
  );
}

export default App;
