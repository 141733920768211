

import React, { useState, useEffect } from 'react';
import './css_components/About.css';
import { IoMdMailOpen } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { fetchData, API_URL } from './strapi';
import { RiFlag2Fill } from "react-icons/ri";
import { SiAircanada } from "react-icons/si";
import fr from '../locales/fr.json'; // Chemin mis à jour
import en from '../locales/en.json'; // Chemin mis à jour
import { useLanguage } from './LanguageContext'; // Hook pour accéder à la langue


function About() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { selectedLanguage } = useLanguage(); // Utiliser le contexte de langue
  const texts = selectedLanguage === 'fr' ? fr : en; // Utiliser les traductions selon la langue sélectionnée
  

  // Récupérer les données depuis Strapi
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetchData('about', selectedLanguage); // Passer selectedLanguage pour la langue
        setProfileData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching profile data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
  
    fetchProfileData();
  }, [selectedLanguage]); // Recharger les données si la langue change


  if (error) return <p>Erreur lors du chargement des données.</p>;

  const imageUrl = profileData?.attributes?.profile?.data?.[0]?.attributes?.url
    ? `${API_URL}${profileData.attributes.profile.data[0].attributes.url}`
    : null;

  

    return (
      <div className="container">
        <div className="hero">
          <div className='header-hero'>
            <div className='content-hero'>
              <h1>{profileData?.attributes?.titre }</h1>
              <h3>{profileData?.attributes?.baseline }</h3>
            </div>
            <div className='img-hero'>
              <div className='bloc-img-hero'>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={profileData?.attributes?.titre || 'Profile'}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <p>Aucune image disponible</p>
                )}
              </div>
            </div>
          </div>
          <div className='footer-hero'>
            <div className='separate-hero'></div>
            <div className='contact'>
              <div className='info-mail'>
                <span><IoMdMailOpen /></span>
                <p>balluais.maxime@gmail.com</p>
              </div>
              <div className='info-ld'>
                <a href="https://www.linkedin.com/in/maxime-balluais/" target="_blank" rel="noopener noreferrer">
                  <span id="ld"><FaLinkedinIn /></span>
                  <p>{texts.linkedin}</p> {/* Corrigé de 'tests.linkedin' à 'texts.linkedin' */}
                </a>
              </div>
            </div>
          </div>
        </div>
  
        <div className="about-container">
          <h2>{texts.about}</h2>
          <div className="about-paragraph">
            <p>{profileData?.attributes?.description }</p>
          </div>
        </div>
  
        <div className="skills-container">
          <h2>{texts.find}</h2>
          <div className="skills">
            {Array.isArray(profileData?.attributes?.skills) ? (
              profileData.attributes.skills.map((skill, index) => (
                <div key={index} className="one-skill">
                  <p>{skill}</p>
                </div>
              ))
            ) : (
              <p></p>
            )}
          </div>
        </div>
  
        <div className="info-container">
          <div className="info">
            <div className="item-container">
              <span><MdLocationOn /></span>
              <p>{profileData?.attributes?.textlocation }</p>
            </div>
            <div className="item-container">
              <span><RiFlag2Fill /></span>
              <p>{profileData?.attributes?.textdate }</p>
            </div>
            <div className="item-container">
              <span><SiAircanada /></span>
              <p>{profileData?.attributes?.textpvt }</p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default About;
